.App {
  height: 100%;
}

.App-Swiper.App-Swiper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}

.App-SwiperSlide {
  max-width: 1000px;
  height: 100%;
}

.MuiPaper-root.MuiPaper-root {
  border-radius: 24px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.MuiButton-root.MuiButton-root {
  border-radius: 36px
}
