.Loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loader-giftbox {
    animation: pluse 15s linear infinite alternate;
    height: 40vmin;
}

@keyframes pluse {
    from {
        transform: scale(1.0);
    }

    to {
        transform: scale(4);
    }
}
