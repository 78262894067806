:root:root {
  --color-light-pink: #ffd6d7;
  --color-almost-black: #4f4f4f;
  --color-green: #399918;
  --color-light-green: #7fc269;
  --swiper-theme-color: #399918;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-light-pink);
  background-image: url("../public/pattern.jpg");
  background-repeat: repeat;
}

h1, h2, p {
  font-family: "Noto Sans Variable", sans-serif;
  color: var(--color-almost-black);
}

p, input[type=text] {
  font-size: 0.875rem;
}
