.GiftCard-frame {
    padding: 17px;
    max-width: 830px;
    margin: auto;
    margin-top: calc(50vh - 400px);
}

@media (max-height: 800px) {
    .GiftCard-frame {
        margin-top: 0;
    }
}

.GiftCard {
  height: 100%;
}

.GiftCard-content.GiftCard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
}

.GiftCard-header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin: 0 0 17px;
}

.GiftCard-header-booked {
  text-decoration: line-through;
}

.GiftCard-header h2 {
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.GiftCard-photo {
  max-height: 500px;
  max-width: 100%;
}

.GiftCard-details {
  width: 100%;
  margin-top: 12px;
}

.GiftCard-details-row {
  display: flex;
  color: #646464;
  align-items: center;
  gap: 5px;
}

.GiftCard-details-row p {
  margin: 12px 0;
}

.GiftCard-details-label {
  font-weight: 700;
  color: #646464;
}
